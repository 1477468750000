import * as React from "react"
import ProjectCard from "../components/project-card"
import LogoCard from "../components/logo-card"

const components = {
  // eslint-disable-next-line react/display-name
  ProjectCard: ({ link, title, bg, children, ...props }) => (
    <ProjectCard {...props} link={link} title={title} bg={bg}>
      {children}
    </ProjectCard>
  ),
  LogoCard: ({ link, title, img, ...props }) => (
    <LogoCard {...props} link={link} title={title} img={img}/ >
  ),
}

export default components
