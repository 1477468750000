/** @jsx jsx */
import { jsx, Card, Image, Text, Link } from "theme-ui"

type LogoCardProps = {
  link: string
  title: string
  img: string
}

const LogoCard = ({ link, title, img }: LogoCardProps) => (
  <Card
    sx={{
      maxWidth: 320,
      textAlign: "center"
    }}>
    <Link href={link} target="_blank">
      <Image src={img} sx={{borderRadius: 5}} />
    </Link>
    <Text>{title}</Text>
  </Card>
)

export default LogoCard
